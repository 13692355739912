.my-stories-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .card {
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    .card-title {
      font-size: 1.125rem;
    }
  }
}
