.custom-switch {
  display: flex;
  justify-content: flex-end;

  .custom-switch-input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .custom-switch-label .custom-switch-slider {
      background-color: $brand; /* Blue background when checked */

      &:before {
        background-color: $white;
      }
    }

    &:checked + .custom-switch-label .custom-switch-slider:before {
      transform: translateX(26px); /* Move the slider to the right */
    }
  }

  .custom-switch-label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 1.5rem;
    margin: 0;
    cursor: pointer;

    .custom-switch-slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc; /* Default grey background */
      transition: 0.4s;
      border-radius: 1.5rem;

      &:before {
        position: absolute;
        content: "";
        height: 1rem;
        width: 1rem;
        left: 4px;
        bottom: 4px;
        background-color: $brand;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }

  span {
    order: -1; /* Position text to the left of the slider */
    margin-right: 10px; /* Add some spacing between text and slider */
  }
}
