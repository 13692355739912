.sidebar {
  width: 16rem;
  height: 100vh;
  border-radius: 1rem;
  box-shadow: $brand-transparent 0px 5px 10px 0px;
  padding: 1rem 0 1rem 1rem;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-out;

  &.collapsed {
    width: 70px;
    padding: 1rem 0;

    .brand {
      justify-content: center;

      .logo {
        height: 36px;
        width: 36px;
      }
    }

    li {
      justify-content: center;
      gap: 0;
    }
  }

  button.toggle-button svg:hover {
    filter: drop-shadow(0 1px 15px rgba(24, 54, 32, 0.302));
  }

  button.logout-button {
    padding: 0;
  }

  .brand {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
    padding: 0 1rem;

    .logo {
      height: 60px;
      width: 60px;
      transition:
        width 0.3s ease-in-out,
        height 0.3s ease;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style-position: inside;
    padding-left: 0;
  }

  li {
    display: flex;
    align-items: center;
    color: rgb(103, 116, 142);
    cursor: pointer;
    gap: 0.55rem;
    padding: 0 1rem;

    a,
    button {
      display: flex;
      gap: 0.55rem;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      color: rgb(103, 116, 142);
      cursor: pointer;
      letter-spacing: -0.4px;
      line-height: 22.4px;
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: ease-in-out; /* Add a transition effect to the text */
      white-space: nowrap;
      -moz-osx-font-smoothing: grayscale;
    }

    svg {
      color: $brand;
      font-size: 0.75rem;
      align-items: center;
      background-position-x: 50%;
      background-position-y: 50%;
      border-radius: 8px;
      box-shadow: $brand-transparent 0px 1px 5px 0px;
      cursor: pointer;
      padding: 0.75rem;
      -moz-osx-font-smoothing: grayscale;
      background-color: $white;
    }

    &:hover {
      a,
      button {
        color: $brand;
        transform-origin: left;
        transform: scale(1.2);
        transition-duration: 0.15s;
        transition-timing-function: ease;
        font-weight: bold;
        font-size: 0.9em;
      }

      svg {
        box-shadow: $brand-transparent 0px 5px 10px 0px;
      }
    }
  }
}
