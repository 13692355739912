/* General Styles */

.new-story-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  transition: all 0.3s;
}

/* Step Navigation Styles */
.step-nav {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem;

  &.input-button {
    justify-content: end;
  }
}

// .step-nav button {
//   background-color: $brand;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   padding: 10px 20px;
//   font-size: 14px;
//   cursor: pointer;
//   transition: background-color 0.3s;
// }

// .step-nav button:hover {
//   background-color: #0055b3;
// }

/* Step Content Styles */
.step-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .form-control:not(:first-child) {
    margin-top: 1.5rem;
  }
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.source-card,
.card {
  position: relative;
  display: flex;
  word-wrap: break-word;
  background-color: #fff;
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 20px 27px 0px;
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  align-items: center;
  gap: 1rem;
}

.dotloader-wrapper {
  min-height: 200px;
  display: grid;
  place-items: center;
  border-radius: 0.375rem;
  background-color: $card-background;
  padding: 5rem;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 20px 27px 0px;
  box-sizing: border-box;
  color: $brand;
  -moz-osx-font-smoothing: grayscale;
}

.question-container {
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin: 2rem 0;
  }
}

.question-container p {
  font-size: 1.1rem;
}

.outline-container {
  gap: 1rem;
}

.outline-intro-container {
  display: flex;
  flex-direction: column;

  textarea {
    height: 14rem;
  }
}

.finished-article-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  .copy-button {
    position: absolute;
    top: -4rem;
    right: -1rem;
    font-size: 1rem;
    margin: 1rem;
    cursor: pointer;
    z-index: 2;
  }

  h1 {
    font-size: 1.5rem;
    color: #183620;
    position: relative;
    margin-bottom: 1.3rem;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      color: #183620;
      border-bottom: 2px solid #183620;
      left: 0;
      bottom: -1rem;
    }
  }

  h2 {
    font-size: 1.125rem;

    &:last-of-type {
      color: black;
      font-size: 1.25rem;
    }
  }

  a {
    color: $brand;

    &:hover {
      text-decoration: underline;
    }
  }
}

.loading-indicator {
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  gap: 0.5rem;
}

.dashboard-input-wrapper hr {
  border: 0;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, rgba(24, 54, 32, 0.75), rgba(24, 54, 32, 0));
}

.article-settings__profile {
  margin-top: 1rem;
}

.error-message {
  color: #d8000c;
  background-color: #ffbaba;
  border: 1px solid #d8000c;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.error-message .icon {
  margin-right: 10px;
}

.error-message .icon svg {
  fill: #d8000c;
  width: 20px;
  height: 20px;
}
