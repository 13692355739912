.home-container,
.auth-page-container {
  margin-top: 5rem;
  justify-content: center !important;
  flex-direction: column;
  display: flex;
}

.wrapper-container {
  border-radius: 0.375rem;
  background-color: $card-background;
  padding: 5rem;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 20px 27px 0px;
  box-sizing: border-box;
  color: $brand;
  -moz-osx-font-smoothing: grayscale;

  
}

h1 {
  font-size: 3rem;
  color: $brand;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
  color: $brand;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.benefit-icon {
  font-size: 2.5rem;
  color: $brand;
}

.hero-section {
  min-height: 80vh;
  background-color: #e5e5f7;
  opacity: 0.9;
  background-image:  url("../../images/hero-background.svg");
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-card {
  background-color: #f8f9fa;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.quote-icon {
  font-size: 2rem;
  color: $brand;
  margin-bottom: 1rem;
}

.testimonial-text {
  font-style: italic;
  font-size: 1.1rem;
}

.testimonial-author {
  font-weight: bold;
  color: #6c757d;
}

.btn-hover-effect {
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: darken($brand, 10%);
    color: #fff;
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInAnimation 0.5s forwards;

  @keyframes fadeInAnimation {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.how-it-works-section li {
  padding: 1.5rem;
  margin: 1rem 0;
  border: none;
}

.cta-section .btn-white {
  color: $brand;
  background-color: #fff;
}
