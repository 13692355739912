// $theme-colors: (
//   "primary": $primary,
//   "secondary": $secondary,
//   "success": $success,
//   "info": $info,
//   "warning": $warning,
//   "danger": $danger,
//   "light": $light,
//   "dark": $dark
// );

.mt-5 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
