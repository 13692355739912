.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: $white;
  background-color: #f8f9fa;
  border-bottom: $brand;
}

.navbar-nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.nav-link {
  display: block;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.55);
  text-decoration: none;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  color: rgba(0, 0, 0, 0.9);
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
