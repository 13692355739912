/* colors */

// $primary: #228138;
// $secondary: #e1b265;
$background: #efefef;
// $accent: #de8da5;
$white: #ffffff;
$card-background: $white;
// $text: #5c5c5c;

$brand: #183620;
$brand-transparent: #1836204d;

$theme-colors: (
  primary: $brand
);

$component-active-color: $white;
$component-active-bg: $brand;

// @import "~bootstrap/scss/bootstrap";
@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
