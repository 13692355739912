body {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
}

.container-fluid {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.dashboard-wrapper {
  display: flex;
}

.dashboard-container {
  display: grid;
  place-items: center;
  width: 100%;
  overflow-y: scroll;
  max-height: 100vh;
  padding: 6rem 0;
}

.dashboard-input-wrapper {
  width: 80%;
}

.dashboard-input-container {
  border-radius: 0.375rem !important;
  background-color: $card-background;
  padding: 1.5rem !important;
  margin: 1.5rem !important;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 20px 27px 0px;
  box-sizing: border-box;
  color: $brand;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  overflow-wrap: break-word;
  position: relative;
  tab-size: 4;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border-collapse: collapse;
  margin: 1rem 0;
}

thead {
  box-sizing: border-box;
  color: rgb(131, 146, 171);
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 16px;
  opacity: 0.7;
  overflow-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  -moz-osx-font-smoothing: grayscale;

  .author {
    text-align: left;
  }
}

.organization-name {
  text-align: left;
}

tr {
  border-bottom: 1px solid #e9ecef;
}

// td,
// th {
//   vertical-align: middle;
//   text-align: center;
//   padding: 0.5rem 0.5rem;

//   &.author {
//     text-align: left;
//   }
// }

.my-stories-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
