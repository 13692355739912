.admin-wrapper h1 {
  margin-bottom: 1rem;
}

.delete-button svg {
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: red;
  }
}
